import './index.scss'
// import Logo from '../../../assets/images/threesteps.jpg'
import OwnerLogo from '../../../assets/images/qasim-bayramov.jpg'
import 'react-toastify/dist/ReactToastify.css'
import RandevuForm from '../randevuForm'

const Randevu = () => {
    return (
        <div className="online-randevu" id="online-randevu">
            <div className="content">
                <div className="person-info">
                    <div className="profile-img">
                        <img src={OwnerLogo} alt="Qasım Bayramov"/>
                    </div>
                    <p>Ph.D Qasım Bayramov</p>
                    <p>Tibb elmləri fəlsəfə doktoru</p>
                    <p>Ali Dərəcəli Həkim</p>
                    <p>Stomatoloq - İmplantoloq</p>
                    <b style={{
                        color: '#fff',
                        background: '#7854f7',
                        padding: '10px',
                        borderRadius: '6px'
                    }}>ONLİNE NÖVBƏ GÖTÜRMƏK</b>
 <iframe title="adress"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.4300009262492!2d49.826629084883436!3d40.3993239830793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dfaf308d4af%3A0x7275cfe4bab5feb1!2sATU%20Stomatoloji%20Klinika%20T%C9%99dris!5e0!3m2!1sen!2saz!4v1692090086614!5m2!1sen!2saz"
                        style={{border: 0, borderRadius: '6px'}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>
                <RandevuForm/>
            </div>
            {/* <div className="info">
                <div className="left-content">
                    <a target="_blank" href="https://www.youtube.com/watch?v=-WZbqcMt28k&feature=share">
                        <i className="pi pi-youtube"/>
                        "Onlayn Randevu"proqramı ilə tanışlıq üçün klikləyin.
                    </a>
                    <a target="_blank" href="tel:+994504713471">
                        <i className="pi pi-phone"/>
                        +994504713471
                    </a>
                    <a target="_blank" href="tel:+994504713471">
                        <i className="pi pi-whatsapp"/>
                        +994504713471
                    </a>
                </div>
                <div className="right-content">
                    <a href="https://threesteps.az" target="_blank">
                        <img width="170" height="100" src={Logo} alt="ThreeSteps"/>
                    </a>
                </div>
            </div> */}
        </div>
    )
}

export default Randevu
